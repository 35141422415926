<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="black" fill-opacity="1"
            d="M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,138.7C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>
    <div class=" d-flex justify-content-center align-items-center">
        <div class="about-info col-md-7">
            <h2>Welkom bij Sensual Pleasure Tantra</h2>
            <p class="about-info-tekst text-left">Wij zijn John en Lin, en we heten je van harte welkom bij onze tantra
                massagesalon. Met passie en toewijding bieden we een unieke ervaring die je helpt om diep te ontspannen en
                je innerlijke balans te vinden.
                <br>
                <br>
                Wij zijn beide toegewijde beoefenaars van tantra en begeleiden mensen graag op hun pad naar welzijn en
                zelfontdekking. We delen graag onze kennis en ervaringen met jou, om je te helpen een diepere verbinding met
                jezelf te maken.
                <br><br>
                Bij ons kun je rekenen op een liefdevolle en zorgzame benadering. Wij zijn zeer zachte en lieve mensen die
                met veel aandacht en respect met jou omgaan. Onze tantra massages zijn ontworpen om zowel lichaam als geest
                te verjongen. Met zorg en aandacht voor jouw persoonlijke behoeften creëren we een veilige en rustgevende
                omgeving waar je volledig tot rust kunt komen en jezelf kunt herontdekken.
                <br><br>
                Bij ons staat persoonlijke aandacht centraal. We nemen de tijd om naar je te luisteren en je te begeleiden
                op een reis van zelfontdekking en innerlijke harmonie. Of je nu op zoek bent naar stressverlichting of
                diepere ontspanning, wij zijn hier om je te ondersteunen en te begeleiden.
                <br><br>
                Wij nodigen je uit om de helende kracht van tantra massage te ontdekken en te ervaren wat wij voor je kunnen
                betekenen. Voel je vrij om contact met ons op te nemen voor meer informatie of om een afspraak te maken.
                <br><br>
                Met warme groet,
                <br>
                John en Lin
            </p>
        </div>
        <div class="overons col-md-5">
            
        </div>

    </div>
</template>

<script>
export default {
    name: 'AboutView'
}


</script>

<style scoped>
h2 {
    font-family: 'Playfair Display', serif;

    border-bottom: 4px solid #CBA135;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    font-size: 3rem;
    color: #2c3e50;

}

.about-info {
    text-align: left;
    padding-left: 6rem;
}

.overons img{
    width: 60%;
    
    
}

@media screen and (max-width: 768px) {
    .about-info {
        padding: 1rem;
        padding-top: 4rem;
    }
    .d-flex{
        display: block !important;
    }

    .overons img{
        width: 90%;
        padding-bottom: 1rem;
    }

     h2 {
        font-size: 2rem;
       
    }
   
    
    

    
}

</style>